.tracks-collector {
  margin-bottom: 30px;
  padding: 30px;
}

.tracks-collector .tracks-collector-info {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
}

.tracks-collector .tracks-collector-info .track-image {
  flex-shrink: 0;
  width: 150px;
  height: 150px;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}

.tracks-collector-header {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 0;
  min-height: 70px;
  margin-left: 30px;
}

.tracks-collector .tracks-collector-info .tracks-collector-title {
  overflow: hidden;
  color: white;
  font-weight: bold;
  font-size: 48px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tracks-collector .tracks-collector-stats {
  display: flex;
  margin-top: 15px;
}

.tracks-collector .tracks-collector-stats span {
  margin: 0 3px;
  color: rgb(255 255 255 / 0.7);
  font-size: 14px;
}

.tracks-collector .tracks-collector-popularity {
  width: 100px;
}

.tracks-collector .play-button {
  width: 70px;
  margin-right: 50px;
  margin-left: 20px;
  cursor: pointer;
}

.tracks-collector .play-button:active {
  width: 80px;
  margin-right: 45px;
  margin-left: 15px;
}

.tracks-collector .play-button.disabled {
  cursor: default;
  opacity: 0.3 !important;
}

.tracks-collector-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.tracks-collector-actions * {
  margin-right: 10px;
}

.tracks-collector .tracks-collector-empty-button {
  display: inline-block;
  padding: 5px 15px;
  color: white;
  font-size: 15px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tracks-collector-empty-button:hover {
  background-color: hsla(0, 0%, 100%, 0.2);
}
