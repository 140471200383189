input.suggest-input {
  font-size: 24px;
  padding: 10px 12px;
  border-radius: 10px;
  width: 50%;
  box-sizing: border-box;
  color: #fff;
  background-color: #1c1c1d;
  border: 2px solid rgba(255, 255, 255, 0.1);
}

input.suggest-input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

input.suggest-input:focus {
  outline: 0;
  border: 2px solid #036551;
  background-color: #1c1c1d;
  box-shadow: 0 0 0 3px #013a2f;
}

.mobile input.suggest-input {
  width: 90%;
  font-size: 5vw;
}
