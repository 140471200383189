.popularity-bar {
  width: 100%;
  border: 1px solid hsl(0deg 0% 100% / 0.2);
}

.popularity-bar .popularity-value {
  background-color: hsl(0deg 0% 100% / 0.2);
  color: white;
  font-size: 11px;
  padding: 2px 5px;
  box-sizing: border-box;
  text-align: left;
}
