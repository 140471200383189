.genre-explorer {
  height: 100%;
  padding: 10px 30px 40px;
  overflow-y: auto;
}

.genre-explorer .scroll-container {
  display: flex;
  flex-flow: wrap;
}

.year-label {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 15px;
  color: #f1ecec;
  font-weight: bold;
  font-size: 48px;
}

.year-label strong {
  white-space: nowrap;
}

.loading-caption {
  color: white;
  font-size: 14px;
}

.genre-explorer .album-tile {
  margin-right: 30px;
  margin-bottom: 30px;
}
