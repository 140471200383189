.player-select {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-left: 20px;
  color: white;
  font-size: 13px;
  white-space: pre-wrap;
}

.player-select > button,
.player-select-menu > .player-name {
  margin-left: 5px;
}

.player-select .icon-device {
  margin-left: 10px;
  cursor: pointer;
  height: 20px;
}

.player-select .icon-arrow {
  cursor: pointer;
  height: 14px;
}

.player-select .player-name,
.player-select .player-name-not-found {
  white-space: nowrap;
  cursor: pointer;
}

.players-menu {
  font-size: 16px;
}

.now-playing-track,
.now-playing-artist {
  font-size: 13px;
  overflow: hidden;
  text-wrap: nowrap;
}

.now-playing-track:hover,
.now-playing-artist:hover {
  text-decoration: underline;
}

.now-playing-artist {
  color: rgb(255 255 255 / 0.7);
  font-weight: 400;
}

.player-select-menu {
  display: flex;
  align-items: center;
}

.player-select .rc-menu-button.track-menu-button {
  font-size: 14px;
}

.player-select .player-controls {
  display: flex;
  margin-right: 30px;
}

.player-controls a {
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  padding: 4px;
  margin: 4px;
  border-radius: 50%;
}
.player-controls a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (hover: none) {
  .player-controls a:hover {
    background-color: inherit;
  }
}

.player-controls img {
  height: 30px;
  width: 30px;
}

.player-select .player-item {
  display: flex;
  flex-direction: row;
}

.player-select .player-item .player-item-info {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-left: 10px;
}

.player-select .player-item .player-item-artwork {
  width: 30px;
  height: 30px;
  background-size: cover;
}

.mobile .player-select {
  margin-left: 10px;
  margin-right: 20px;
}

.mobile .player-controls {
  margin-right: 10px;
  flex-grow: 1;
}

.mobile .player-controls a {
  margin: 0;
}

