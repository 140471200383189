.app-navigation {
  min-width: 240px;
  margin: 2px 50px 0;
}

.app-navigation a {
  display: inline-block;
  padding: 6px 12px;
  color: white;
  font-weight: 450;
  font-size: 16px;
  text-decoration: none;
  border-radius: 5px;
  cursor: pointer;
}

.app-navigation a:not(:last-of-type) {
  margin-right: 8px;
}

.app-navigation a:hover {
  background-color: rgb(255 255 255 / 0.2);
}

.app-navigation a.app-nav-point-selected {
  color: #0e7532;
  background-color: white;
  cursor: default;
}
