.spotify-album-explorer .flowchart-node {
  width: 250px !important;
  height: 100px;
  pointer-events: auto !important;
}

.album-flowchart-node {
  border: 0 !important;
  width: 250px !important;
  height: 100px !important;
  border: none !important;
  cursor: default !important;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 5px;
  position: relative;
}

.album-flowchart-node .node-toggle {
  position: absolute;
  right: -10px;
  top: 40px;
  background: #303030;
  z-index: 100;
  border-radius: 50%;
  width: 21px;
  height: 21px;
  text-align: center;
  cursor: pointer;
}

.album-flowchart-node .album-tile {
  display: flex;
  flex-direction: row;
  width: auto;
  margin: 0;
  height: 100px;
  box-sizing: border-box;
}

.album-flowchart-node .album-image {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 100px;
  height: 100px;
  background-size: cover;
}

.album-flowchart-node .album-info {
  width: 150px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  box-sizing: border-box;
  overflow-y: hidden;
}

.album-flowchart-node .album-info .album-artist {
  font-weight: normal;
  font-size: 13px;
  color: #b3b3b3;
  text-align: left;
  margin-top: 5px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.album-flowchart-node .album-info .album-name {
  color: white;
  font-weight: bold;
  font-size: 13px;
  text-align: left;
  margin-top: 5px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.spotify-album-explorer .react-flow__node-default {
  background: transparent;
  text-align: left;
}

.album-flowchart-node .react-flow__node-default .react-flow__handle {
  background: #cfcfd2;
  right: 0;
}

.album-flowchart-node .react-flow__handle-left {
  left: 0;
  z-index: 100;
}

.spotify-album-explorer .react-flow__edge-path {
  stroke: #303030;
}
