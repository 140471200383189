.album-selection-collect-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 55px;
  z-index: 1000;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.album-selection-collect-button button {
  margin-top: 4px;
}

.album-selection-collect-button img {
  width: 30px;
  height: 30px;
}

.mobile .album-selection-collect-button {
  top: 40px;
  width: 30px;
  height: 30px;
}

.mobile .album-selection-collect-button button {
  margin-top: 4px;
}

.mobile .album-selection-collect-button button img {
  width: 22px;
  height: 22px;
}

.mobile .album-selection-collect-button-large-screen {
  top: 10px;
}

.mobile .album-selection-collect-button button {
  top: 4px;
}
