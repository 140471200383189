.genres-browser {
  margin: 40px 30px 30px;
}

.genres-browser .selection-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.genres-browser .top-link-wrapper {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
  display: none;
  align-items: center;
  padding: 5px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 10px;
  cursor: pointer;
}

.genres-browser .top-link-wrapper.visible {
  display: block;
}

.genres-browser .top-link-wrapper button {
  background: none;
  border: none;
  cursor: pointer;
}

.genres-browser .top-link-wrapper img {
  position: relative;
  top: 3px;
}

.genres-browser .genres-browser-browse-all {
  margin-top: 10px;
  text-align: center;
}

.genres-browser .genres-browser-browse-all a {
  padding: 5px 20px;
  color: #f1ecec;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.genres-browser .genres-browser-browse-all a span {
  display: inline-block;
  padding: 8px 10px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 5px;
}

.mobile .genres-browser {
  margin: 20px 20px 40px;
}

.mobile .genres-browser .genres-browser-browse-all a {
  font-size: 16px;
}

.mobile .genres-browser .suggest-input {
  width: 100%;
}

.mobile .genres-browser .top-link-wrapper {
  top: 20px;
  right: 10px;
}
