.bridge {
  padding: 30px;
  color: white;
}

.bridge .bridge-greeting {
  color: white;
  font-size: 14px;
}

.bridge .bridge-greeting2 {
  color: white;
  font-size: 30px;
  font-weight: bold;
  margin-top: 2px;
}

.bridge .bridge-section {
  margin-top: 30px;
  margin-bottom: 40px;
}

.bridge .bridge-section-title {
  display: flex;
  flex-direction: row;
}

.bridge .bridge-section h2 {
  color: #4ad3af;
  font-weight: bold;
  margin: 0;
  font-size: 28px;
  letter-spacing: 1px;
  flex-grow: 1;
  display: inline-block;
}

.bridge .bridge-section-list {
  margin-top: 10px;
}

.bridge .bridge-section.bridge-genres,
.bridge .bridge-section.bridge-labels {
  margin-top: 0;
}

.bridge-section-list .rc-menu-container {
  display: inline-block;
}

.bridge .artist-tile,
.bridge .album-tile {
  padding: 10px 20px 10px 0;
}

.bridge .bridge-section-list .rc-menu-button {
  background-color: transparent;
  color: white;
  border: none;
  padding: 0;
  cursor: pointer;
  display: inline-block;
}

.bridge .bridge-genres-list,
.bridge .bridge-labels-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 10px;
  margin-top: 20px;
  position: relative;
}

.bridge .bridge-genres-list .bridge-genre,
.bridge .bridge-labels-list .bridge-label {
  font-size: 14px;
  color: #f1ecec;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
}

.bridge .bridge-genres-list a::before,
.bridge .bridge-labels-list a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background-color: #3bad8f;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.bridge .bridge-genres-list a:hover::before,
.bridge .bridge-labels-list a:hover::before {
  transform: scaleX(1);
}

.bridge .bridge-section-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.bridge .bridge-section-more {
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  float: right;
  border-radius: 5px;
  padding: 0 8px;
}

.bridge .bridge-section-more:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
