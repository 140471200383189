.album-details {
  padding: 30px;
  margin-bottom: 30px;
}

.album-details .album {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
}

.album-details .album .album-image {
  width: 150px;
  height: 150px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  cursor: pointer;
}

.album-details .album .album-image.no-cover {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
}

.album-details .album-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 30px;
  min-height: 70px;
}

.album-details .album-info .album-name {
  color: white;
  font-size: 48px;
  font-weight: bold;
}

.album-details .album-info .album-menu {
  color: rgba(255, 255, 255, 0.7);
  font-size: 48px;
  margin-left: 20px;
}

.album-details .album-label a {
  font-size: 14px;
  color: #f1ecec;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  font-weight: bold;
}

.album-details .album-label a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background-color: #3bad8f;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.album-details .album-label a:hover::before {
  transform: scaleX(1);
}

.album-details .album-info .album-info-artists {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.album-details .album-info .album-details-artist {
  display: flex;
  margin-right: 20px;
  align-items: center;
}

.album-details .album-info .album-details-artist .album-details-artist-image {
  width: 32px;
  height: 32px;
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  margin-right: 10px;
}

.album-details .album-info .album-details-artist-name {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.album-details .album-info-stats {
  margin-top: 15px;
  display: flex;
}

.album-details .album-info-stats span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin: 0 3px;
}

.album-details .play-button {
  width: 70px;
  margin-right: 50px;
  margin-left: 20px;
  cursor: pointer;
}

.album-details .play-button:active {
  width: 80px;
  margin-left: 15px;
  margin-right: 45px;
}

.album-details .play-button.disabled {
  opacity: 0.3 !important;
  cursor: default;
}

.album-details .album-stats-popularity {
  width: 100px;
}

.album-details .album-details-tracklist {
  margin-top: 10px;
}

.album-details .album-details-copyrights {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.album-details .album-details-copyrights span {
  margin-bottom: 5px;
  display: block;
  font-size: 13px;
}

.album-details .react-tabs__tab {
  color: #9e9e9e;
  font-size: 15px;
  margin-right: 10px;
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 4px 4px 0 0;
}

.album-details .react-tabs__tab:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.album-details .react-tabs__tab-list {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.album-details .react-tabs__tab--selected {
  background-color: hsla(0, 0%, 100%, 0.05);
  color: #fff;
  border: transparent;
  border-bottom: 3px solid #036551;
  font-weight: 600;
}
