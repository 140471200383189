.artist-mix-tile {
  width: 130px;
  font-size: 14px;
}

.artist-mix-tile .artist-mix-tile-image {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 130px;
  height: 130px;
  background-color: #036551;
  border: 10px solid hsl(0deg 0% 0% / 0.6);
  border-radius: 50%;
}

.artist-mix-tile .artist-mix-tile-image img {
  width: 60px;
  height: 60px;
}

.artist-mix-tile .artist-mix-caption {
  display: inline-block;
  box-sizing: border-box;
  width: 130px;
  margin-top: 15px;
  padding: 5px 15px;
  color: white;
  font-size: 13px;
  background-color: hsl(0deg 0% 100% / 0.1);
  border-radius: 5px;
  cursor: pointer;
}
