.mobile .album-details {
  padding: 0;
}

.mobile .album-details .album-cover {
  position: relative;
}

.mobile .album-details .album-cover .album-image {
  width: 100vw;
  height: 100vw;
  opacity: 0.5;
}

.mobile .album-details .album-cover .album-info {
  position: absolute;
  bottom: 20px;
  width: 100%;
  margin-left: 0;
  text-align: center;
}

.mobile .album-details .album-cover .rc-menu-button.album-menu-button {
  position: absolute;
  top: 0;
  right: 15px;
  color: white;
  font-size: 36px;
}

.mobile .album-details .album-cover .album-info .album-name {
  box-sizing: border-box;
  width: 100%;
  padding: 0 20px;
  font-size: 10vw;
  line-height: 1;
}

.mobile .album-details .album-info-artists {
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.mobile .album-details .album-info-artists button {
  margin-top: 10px;
}

.mobile .album-details .album-info-stats {
  display: flex;
  margin-bottom: 10px;
}

.mobile .album-details .album-info-stats .album-stats-values {
  padding: 0 30px 0 20px;
}

.mobile .album-details .album-info-stats .album-stats-values span {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 8px;
  color: #ffffff;
  font-weight: lighter;
  font-size: 3vw;
  letter-spacing: 3px;
  white-space: nowrap;
  text-align: right;
}

.mobile .album-details .album-info-stats .album-stats-values span:first-of-type {
  font-weight: bold;
}

.mobile .album-details .album-info-stats .album-stats-popularity {
  margin-top: 5px;
}

.mobile .album-details .album-info-stats .play-button {
  position: relative;
  top: -45px;
  right: 20px;
  flex-shrink: 0;
  width: 80px;
  margin: 0;
  opacity: 0.9;
}

.mobile .album-details .album-stats .play-button:active {
  opacity: 1;
}

.mobile .album-details .tab-menu {
  z-index: 10000;
  font-size: 16px;
}

.mobile .album-details .album-details-tracklist {
  margin-top: 20px;
  margin-right: 20px;
  margin-left: 20px;
}

.mobile .album-details .similar-albums {
  margin: 20px 20px 10px;
}

.mobile .album-details .tab-menu-button + .rc-menu-container {
  display: inline-block;
}

.mobile .album-details .tab-menu-button.rc-menu-button {
  display: inline-block;
  margin: 0 10px 0 20px;
  padding: 0;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.mobile .album-details .tab-menu-button.rc-menu-button img {
  margin-left: 4px;
}

.mobile .album-details .album-tab-content {
  margin: 10px 20px;
}

.mobile .album-details .albums-list-sorting-menu.rc-menu-button {
  margin-top: 0;
  margin-left: 10px;
  font-weight: normal;
}

.mobile .album-details .album-details-mobile-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.mobile .album-details .album-details-mobile-actions.similar {
  justify-content: left;
}

.mobile .album-details .album-details-mobile-actions .album-mix-menu-button {
  margin-top: 0;
  margin-right: 20px;
}

.mobile .album-details .albums-list {
  margin-left: 20px;
}
