.spinner {
  position: fixed;
  font-size: 10px;
  text-indent: -9999em;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgb(255 255 255 / 0) 42%);
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 200;
}

.spinner::before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.spinner::after {
  background: #036551;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.spinner.inline {
  position: absolute;
  top: 50%;
}
