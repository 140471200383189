.spotify-explorer .flowchart-node {
  background-color: hsl(0deg 0% 100% / 0.1);
}

.artist-node {
  display: flex;
  align-items: center;
  padding-left: 5px;
  text-align: left;
}

.node-image {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 50%;
}

.artist-node .node-name {
  display: inline-block;
  width: 125px;
  margin-left: 10px;
  color: white;
}

.artist-node .node-toggle {
  position: absolute;
  right: -10px;
  z-index: 100;
  width: 21px;
  height: 21px;
  text-align: center;
  background: #303030;
  border-radius: 50%;
  cursor: pointer;
}

.spotify-explorer .react-flow__node-default .react-flow__handle {
  background: #303030;
  border: 1px solid #4b4a4a;
}

.artist-node .rc-menu-container {
  display: inline-block;
}

.artist-node .rc-menu-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  text-align: left;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.spotify-explorer .react-flow__edge-path {
  stroke: #303030;
}
