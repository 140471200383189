.collect-button {
  position: absolute;
  top: 30px;
  right: 30px;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;
  background-color: #036551;
  border-radius: 50%;
  cursor: pointer;
}

.collect-button:active {
  top: 25px;
  right: 25px;
  width: 80px;
  height: 80px;
}

.collect-button img {
  width: 40px;
  height: 40px;
  fill: white;
}

.collect-button:active img {
  width: 50px;
  height: 50px;
}

.mobile .collect-button {
  top: 20px;
  right: 20px;
  opacity: 0.9;
}

.mobile .collect-button:active {
  opacity: 1;
}
