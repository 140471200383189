.mobile .album-selection-options {
  top: 75px;
  right: 10px;
  width: 30px;
  height: 30px;
}

.mobile .album-selection-options .album-selection-options-button img {
  width: 20px;
  height: 20px;
}

.mobile .album-selection-options-large-screen {
  top: 10px;
}

.mobile .album-selection-options .album-selection-options-button {
  top: 4px;
}

.mobile .album-selection-options .album-selection-options-button .album-selection-custom-options-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: rgb(243 20 20 / 0.8);
  position: absolute;
  top: -8px;
  right: -2px;
}
