.genre-category {
  display: flex;
  flex-direction: row;

  margin-top: 30px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.genre-category.last {
  border-bottom: none;
}

.genre-category.nameless {
  margin-top: 20px;
}

.genre-category .genre-category-info {
  display: flex;
  flex-direction: column;
  width: 220px;
  flex-shrink: 0;
  margin-top: 8px;
}

.genre-category .genre-category-label {
  display: flex;
  flex-direction: row;
}

.genre-category .genre-category-icon {
  width: 20px;
}

.genre-category .genre-category-name {
  color: #4ad3af;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  flex-grow: 1;
  display: inline-block;
  margin: 0 0 0 5px;
}

.genre-category .genre-category-description {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2px;
}

.genre-category .genre-category-toc {
  text-align: center;
  margin-bottom: 30px;
}

.genre-category .genre-category-toc button {
  font-size: 20px;
  display: inline-block;
  padding: 2px 8px;
  color: #f1ecec;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
}

.genre-category .genre-category-toc button:hover {
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 5px;
}

.mobile .genre-category {
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 20px;
}

.mobile .genre-category.nameless {
  margin-top: 0;
}

.mobile .genre-category .genre-category-info {
  width: 100%;
}
