.group-anchor {
  display: inline-block;
}

.genre-group .genre-group-container {
  padding: 0 20px 20px 20px;

  display: flex;
  flex-direction: row;
}

.genre-group .genre-group-container .no-caption {
  padding: 0 0 20px 0;
}

.genre-group .group-name {
  text-decoration: none;
}

.genre-group h4 {
  color: white;
  font-size: 50px;
  font-weight: normal;
  padding: 0;
  margin: 4px 20px 0 0;
  width: 50px;
  text-align: center;
}

.genre-group .group-genres {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: flex-start;
  margin-top: 7px;
  padding-bottom: 20px;
}

.genre-group .group-genres div,
.genre-group .group-genres button {
  padding: 8px;
  width: 200px;
}

.genre-group .group-genres a,
.genre-group .group-genres button {
  font-size: 14px;
  color: #f1ecec;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
}

.genre-group .group-genres a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background-color: #3bad8f;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.genre-group .group-genres a:hover::before {
  transform: scaleX(1);
}

.mobile .genre-group .group-genres a,
.mobile .genre-group .group-genres button {
  font-size: 16px;
}

.genre-group .group-genres .group-collapsed-genres {
  display: none;
}

.genre-group button.expand-all {
  padding: 4px;
}

.genre-group button.expand-all span {
  display: inline-block;
  background-color: hsla(0, 0%, 100%, 0.1);
  border-radius: 5px;
  padding: 4px 8px;
}

.genre-group .top-link {
  padding: 0 20px;
}

.mobile .genre-group .genre-group-container {
  margin-top: 10px;
  padding: 0;
}

.mobile .genre-group .genre-group-container h4 {
  font-size: 40px;
  width: 30px;
}

.mobile .genre-group .group-genres button {
  padding-left: 2px;
}

.mobile .genre-category .genre-category-toc {
  margin-top: 20px;
  text-align: left;
  margin-bottom: 20px;
}

.mobile .genre-group .group-genres button {
  width: 100%;
}

.mobile .genre-group .genre-group-container.no-caption .group-genres button {
  width: 44vw;
}

.mobile .genre-category .genre-category-toc button {
  padding-left: 0;
  padding-right: 10px;
}

.mobile .genre-group .group-genres {
  padding-bottom: 5px;
}
