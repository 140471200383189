.app-title {
  min-width: 230px;
}

.app-title a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.app-title span {
  margin-left: 10px;
  color: white;
  font-size: 28px;
}
