.tracks-sorting-menu.rc-menu-button {
  display: inline-block;
  padding: 0;
  color: white;
  font-size: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.tracks-sorting-menu.rc-menu-button img {
  margin-left: 4px;
}
