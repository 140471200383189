.artist-details {
  padding: 30px;
}

.artist-details .artist {
  display: flex;
  margin-bottom: 30px;
  width: 100%;
  align-items: center;
}

.artist-details .artist .artist-image {
  width: 150px;
  height: 150px;
  background-size: cover;
  border-radius: 50%;
  background-position: center;
  flex-shrink: 0;
  cursor: pointer;
}

.artist-details .artist .artist-image.no-cover {
  border: 1px solid hsla(0, 0%, 100%, 0.1);
}

.artist-details .artist-info {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-left: 30px;
  min-height: 70px;
}

.artist-details .artist-info .artist-name {
  color: white;
  font-size: 48px;
  font-weight: bold;
}

.artist-details .artist-genres {
  margin-top: 5px;
  font-size: 15px;
  line-height: 1.3em;
  width: 70%;
}

.artist-details .artist-genres a {
  font-size: 14px;
  color: #f1ecec;
  position: relative;
  overflow: hidden;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  text-align: left;
  margin-right: 10px;
}

.artist-details .artist-genres a::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  bottom: -2px;
  left: 0;
  background-color: #3bad8f;
  transform: scaleX(0);
  transform-origin: top left;
  transition: transform 0.3s ease;
}

.artist-details .artist-genres a:hover::before {
  transform: scaleX(1);
}

.artist-details .artist-info .artist-menu {
  color: rgba(255, 255, 255, 0.7);
  font-size: 48px;
  margin-left: 20px;
}

.artist-details .play-button {
  width: 70px;
  margin-right: 50px;
  margin-left: 20px;
  cursor: pointer;
}

.artist-details .play-button:active {
  width: 80px;
  margin-left: 15px;
  margin-right: 45px;
}

.artist-details .play-button.disabled {
  opacity: 0.3 !important;
  cursor: default;
}

.artist-details .artist-disco-section {
  display: flex;
  flex-flow: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}

.artist-details .album-tile {
  margin-bottom: 20px;
  margin-right: 30px;
}

.artist-details .artist-stat {
  margin-right: 20px;
  color: white;
}

.artist-details .artist-stat .stat-name {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
}

.artist-details .artist-stat .stat-value {
  font-size: 22px;
  margin-top: 5px;
  color: white;
  text-align: right;
  font-weight: 600;
}

.artist-details .react-tabs__tab {
  color: #9e9e9e;
  font-size: 15px;
  margin-right: 10px;
  padding: 10px 15px;
  background-color: transparent;
  border-radius: 4px 4px 0 0;
}

.artist-details .react-tabs__tab:hover {
  color: #fff;
  background-color: hsla(0, 0%, 100%, 0.1);
}

.artist-details .react-tabs__tab-list {
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.artist-details .react-tabs__tab--selected {
  background-color: hsla(0, 0%, 100%, 0.05);
  color: #fff;
  border: transparent;
  border-bottom: 3px solid #036551;
  font-weight: 600;
}

.artist-details .artist-tile,
.artist-details .artist-mix-tile {
  margin: 0 30px 30px 0;
}

.artist-details .tracklist {
  margin-bottom: 30px;
}

.album-mix-menu-button,
.artist-mix-menu-button {
  margin-top: 10px;
}
