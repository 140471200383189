.app-container.mobile .app-navigation {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.app-container.mobile .app-navigation a,
.app-container.mobile .player {
  display: block;
  margin-bottom: 10px;
  padding: 4px 10px;
  color: #4ad3af;
  font-size: 5vw;
  text-decoration: none;
  cursor: pointer;
}

.app-container.mobile .app-navigation a {
  width: 150px;
}

.app-container.mobile .player {
  width: 240px;
}

.app-container.mobile .player.selected {
  color: #0e7532;
  background-color: white;
  border-radius: 5px;
  cursor: default;
}

.app-container.mobile .app-navigation a.app-nav-point-selected,
.app-container.mobile .player.selected {
  padding-top: 6px;
  color: black;
  background-color: #4ad3af;
  border-radius: 5px;
  cursor: default;
}

.app-container.mobile .app-title {
  margin: 5px 0 0 7px;
}

.app-container.mobile .app-title img {
  z-index: 1000;
  width: 50vw;
}

.app-container.mobile h3 {
  margin: 30px 10px 10px;
  padding-bottom: 5px;
  color: white;
  font-weight: bold;
  font-size: 7vw;
  letter-spacing: 2px;
}

.mobile .playback-hint {
  width: 70%;
  margin-top: 10px;
}

.app-container.mobile .no-players {
  margin: 0 20px 0 10px;
  color: #757575;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.4;
}

.app-container.mobile .no-players span {
  text-decoration: underline;
}

.app-container.mobile .social-links {
  margin-left: 10px;
}

.app-container.mobile .social-links a {
  margin-right: 15px;
}

.app-container.mobile .legal {
  position: absolute;
  bottom: 20px;
  left: 20px;
  margin-right: 20px;
  color: #4ad3af;
  font-size: 12px;
}

.app-container.mobile .app-logo-wrapper {
  margin-top: 10px;
  margin-left: 10px;
}

.app-container.mobile .app-logo {
  font-size: 24px;
}