.playlist-name-dialog-content {
  width: 500px;
  padding: 20px;
  background-color: #22262c;
  border-radius: 10px;
}

.playlist-name-dialog-content .playlist-name-dialog-caption {
  display: inline-block;
  margin-bottom: 16px;
  color: white;
  font-size: 22px;
}

.playlist-name-dialog-content input {
  box-sizing: border-box;
  width: 100%;
  padding: 14px 20px 10px;
  font-size: 24px;
  border: none;
  border-radius: 10px;
  outline: 0;
}

.playlist-name-dialog-content button {
  float: right;
  height: 40px;
  margin-top: 20px;
  padding: 0 50px;
  color: #ffffff;
  font-size: 16px;
  background-color: #ee445e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.playlist-name-dialog-content button.disabled {
  color: grey;
  background-color: #b8b8b8;
  pointer-events: none;
}

.mobile .playlist-name-dialog-content {
  width: 80%;
}

.mobile .playlist-name-dialog-content .playlist-name-dialog-caption {
  font-size: 5vw;
}

.mobile .playlist-name-dialog-content input {
  font-size: 4vw;
}
