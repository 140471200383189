.mobile .tracks-collector {
  padding: 0;
}

.mobile .tracks-collector .tracks-collector-cover {
  position: relative;
}

.mobile .tracks-collector .tracks-collector-cover .track-image {
  width: 100vw;
  opacity: 0.5;
  height: 100vw;
}

.mobile .tracks-collector .tracks-collector-cover .tracks-collector-info {
  position: absolute;
  bottom: 30px;
  text-align: center;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

.mobile .tracks-collector .tracks-collector-cover .tracks-collector-info .tracks-collector-title {
  font-size: 10vw;
  line-height: 1;
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.mobile .tracks-collector .tracks-collector-cover .tracks-collector-info .tracks-collector-stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile .tracks-collector .tracks-collector-cover .tracks-collector-info .tracks-collector-stats span {
  margin-bottom: 15px;
  background-color: rgb(0 0 0 / 0.6);
  padding: 8px 15px;
  color: white;
}

.mobile .tracks-collector .tracks-collector-actions {
  margin-top: 0;
  align-items: start;
  position: relative;
}

.mobile .tracks-collector .tracks-collector-actions * {
  margin: 0 0 10px;
}

.mobile .tracks-collector .tracks-collector-actions .tracks-collector-main-actions {
  flex-grow: 1;
  padding: 15px 0 0 20px;
  max-width: 80%;
}

.mobile .tracks-collector .tracks-collector-actions .tracks-collector-main-actions * {
  margin-right: 10px;
}

.mobile .tracks-collector .tracks-collector-actions .play-button {
  margin: 0 !important;
  width: 80px;
  position: absolute;
  top: -35px;
  opacity: 0.9;
  right: 20px;
  flex-shrink: 0;
}

.mobile .tracks-collector .tracks-collector-actions .play-button:active {
  opacity: 1;
}

.mobile .tracks-collector .tracks-collector-tracklist {
  padding-left: 20px;
}
