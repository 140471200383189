.label-category {
  display: flex;
  flex-direction: row;

  margin-top: 30px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
}

.label-category.last {
  border-bottom: none;
}

.label-category .label-category-info {
  display: flex;
  flex-direction: column;
  width: 230px;
  flex-shrink: 0;
  margin-top: 8px;
}

.label-category .label-category-label {
  display: flex;
  flex-direction: row;
}

.label-category .label-category-icon {
  width: 20px;
}

.label-category .label-category-name {
  color: #4ad3af;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 1px;
  flex-grow: 1;
  display: inline-block;
  margin: 0 0 0 5px;
}

.label-category .label-category-description {
  color: rgba(255, 255, 255, 0.75);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 2px;
}

.mobile .label-category {
  flex-direction: column;
  margin-top: 10px;
  padding-bottom: 20px;
}

.mobile .label-category .label-category-info {
  width: 100%;
}
