.albums-list-sorting-menu.rc-menu-button {
  display: inline-block;
  margin: 15px 10px 0 0;
  padding: 0;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.albums-list-sorting-menu.rc-menu-button img {
  margin-left: 4px;
}
