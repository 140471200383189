.tracks-collector-zero {
  padding: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tracks-collector-zero .tracks-collector-zero-title {
  color: white;
  font-size: 48px;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px 0 0 0;
}

.tracks-collector-zero .tracks-collector-zero-desc {
  margin-top: 20px;
  color: white;
  font-size: 24px;
  margin-bottom: 20px;
}

.tracks-collector-zero .tracks-collector-zero-promo {
  display: flex;
  flex-direction: column;
}

.tracks-collector-zero .tracks-collector-zero-images {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tracks-collector-zero .tracks-collector-zero-images p {
  color: white;
  font-weight: 400;
  margin-bottom: 30px;
}

.tracks-collector-zero .zero-image-1 svg {
  width: 500px;
}

.tracks-collector-zero .zero-image-2 svg {
  width: 300px;
}
